import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const Contact = () => {

  const [userData, setUserData] = useState({name:"", email:"", subject:"", message:""});

  const handleChange = (e) =>{
    setUserData(e.target.value);
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const subject = e.target.subject.value;
    const message = e.target.message.value;
    
    if(name && email && subject && message){
      // console.log(name,email, subject, message);
      const data = {Name: name, Email: email, Subject: subject, Message: message};

      axios.post('https://sheet.best/api/sheets/dd033d73-adea-4d24-940e-1c76a0f55577',data).then((response)=>{
        console.log(data);
        e.target.name.value = "";
        e.target.email.value = "";
        e.target.subject.value = "";
        e.target.message.value = "";
      })
      toast.success("Message Submitted.");
    }else{
      toast.error("Please fill your details.")
    }
  }



  return (
    <>
      <section id="contact" className="pb-16">
        <div className="container">
          <h2 className="text-headingColor font-[700] text-[2.5rem] mb-8">
            Get in Touch
          </h2>
          <div className="md: flex justify-between items-center">
            <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
              <iframe
                title="gooogle-maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15085.542533673592!2d72.84708678278894!3d19.046775197509373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8d72abf2d35%3A0x5ba0b162df2aa82e!2sDharavi%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1697327168505!5m2!1sen!2sin"
                className="border-0 w-full h-full "
                allowfull
                screen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100 px-4 lg:px-8 py-8">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-5">
                  <input
                    type="text" name="name"
                    placeholder="Enter your name"
                    onChange={handleChange}
                    className="w-full p-3 focus:outline-none rounded-[5px]"
                  />
                </div>
                <div className="mb-5">
                  <input
                    type="email" name="email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    className="w-full p-3 focus:outline-none rounded-[5px]"
                  />
                </div>
                <div className="mb-5">
                  <input
                    type="text" name="subject"
                    placeholder="Subject"
                    onChange={handleChange}
                    className="w-full p-3 focus:outline-none rounded-[5px]"
                  />
                </div>
                <div className="mb-5">
                  <textarea
                    type="text" name="message"
                    rows={3}
                    placeholder="Write your message"
                    onChange={handleChange}
                    className="w-full p-3 focus:outline-none rounded-[5px]"
                  />
                </div>

                <button type="submit" className="w-full p-3 focus:outline-none rounded=[5px] bg:smallTextColor text-white hover:bg-headingColor text-center ease-linear duration-150">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

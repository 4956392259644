import React, { useState } from 'react';
import toast from 'react-hot-toast';
import {TypeAnimation} from "react-type-animation";

const Chat = ({ isVisible }) => {
  const [chat, setChat] = useState("");

  const handleChange = (e) => {
   setChat(e.target.value);
  }

  const handleClick = (e) => {
    e.preventDefault();
    
    if(chat){
      e.target.value = "";
      toast.success("Your message sent.")
    }else{
      toast.error("Enter Your message");
    }
  }
  
  if( !isVisible ) return null;

  return (
    <>
    <div className='fixed inset-x-2/3 w-[24%] h-[50%] max-[640px]:inset-[5%] max-[640px]:w-80  backdrop-blur-3xl rounded-3xl z-10 flex' >
        <div className='flex flex-col justify-between p-3'>
            <p className=' text-smallTextColor -mono font-[500] p-4 rounded-xl border-[3px] border-s-primaryColor'>
              <TypeAnimation
              sequence={["If you're interested in collaboration, have questions, or just want to say hello, please don't hesitate to reach out"
            ]}
            speed={40}
              />
            </p>
            <div className='flex justify-between'>
            <input onChange={handleChange} name='chat' className='p-4 rounded-xl bg-slate-500 font-medium text-gray-100 outline-none ' type="text" placeholder='Message...' />
            <button onClick={handleClick} className='bg-slate-800 text-white rounded-xl p-3 '>Send</button>
            </div>
       </div>
    </div>
    </>
  )
}

export default Chat